<template>
  <div class="container">
     <div class="btnTOP">
      <el-button type="primary" size="mini" @click="configSave">保存</el-button>
    </div>

    <cardTitleCom cardTitle="全局参数" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <el-form>
            <el-form-item
              label="若您希望新增单据时商品数量默认填充，请勾选"
              class="compact-form-item"
            ></el-form-item>
            <el-radio
              class="marT10"
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11
              "
              label="0"
              >单据新增数量默认为0</el-radio
            >
            <el-radio
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11
              "
              label="1"
              >单据新增数量默认为1</el-radio
            >
            <el-form-item>
              <div class="marT15">
                <span>若您希望业务单据对账后，不能再反审核修改，请勾选</span>
                <div class="x-f">
                  <el-checkbox
                    true-label="1"
                    false-label=""
                    v-model="
                      form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_12
                    "
                    >不允许反审核已对账单据</el-checkbox
                  >
                </div>
                <!--  <el-checkbox-group
                  v-model="
                    form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_12
                  "
                >
                </el-checkbox-group> -->
              </div>
            </el-form-item>
            <el-form-item>
              <div>
                <span>进销存账本金额计算规则，请勾选</span>
                <div class="x-f">
            <el-radio-group v-model="form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_AMOUNT_CALCULATE_RULE_61">
               <el-radio label="1">成本价</el-radio>
               <el-radio label="2">标准售价</el-radio>
               <el-radio label="3">标准进价</el-radio>
             </el-radio-group>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </cardTitleCom>

    <cardTitleCom cardTitle="采购参数" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <el-form>
            <el-form-item
              label="若您在新增采购订单、采购入库单、采购退货单时采购单价为0，您希望"
              class="compact-form-item"
            ></el-form-item>
            <el-radio
              class="marT10"
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21
              "
              label="1"
              >不控制</el-radio
            >
            <el-radio
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21
              "
              label="2"
              >提示，允许继续操作</el-radio
            >
            <el-radio
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21
              "
              label="3"
              >提示，不允许继续操作</el-radio
            >
            <el-form-item>
              <div class="marT20">
                <span>若您允许采购入库单的入库数量大于订单数量，请勾选</span>
                <div class="x-f">
                  <el-checkbox
                    true-label="1"
                    false-label=""
                    v-model="
                      form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_22
                    "
                  >
                    允许入库数量大于订单数量
                  </el-checkbox>
                </div>
              </div>
            </el-form-item>
            <span>采购收货单，必须按采购订单收货，</span>
            <el-radio
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23
              "
              :label="1"
            >
              数量不允许修改
            </el-radio>
            <el-radio
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23
              "
              :label="2"
            >
              价格不允许修改
            </el-radio>

            <div class="marT15">
              <el-checkbox
                false-label=""
                true-label="1"
                v-model="
                  form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_24
                "
                >采购订单只能被引用一次
              </el-checkbox>
            </div>
            <div class="marT15">
              <el-checkbox
                false-label=""
                true-label="1"
                v-model="
                  form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_25
                "
              >
                采购收货单审核后,自动更新商品资料进价
              </el-checkbox>
            </div>
            <div class="marT15">
              <el-checkbox
                false-label=""
                true-label="1"
                v-model="
                  form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_26
                "
              >
                采购收货单审核后,自动更新商品资料主供应商
              </el-checkbox>
            </div>
            <div class="marT15">
              <el-checkbox
                false-label=""
                true-label="1"
                v-model="
                  form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_27
                "
                >库存量高于最高库存允许采购
              </el-checkbox>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="盘点参数" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <el-form>
            <div>
              盘点显示账面数
              <el-switch
                class="marL20"
                active-value="Y"
                inactive-value="N"
                v-model="
                  form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_INVENTORY_31
                "
              >
              </el-switch>
            </div>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="销售参数" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <el-form>
            <div class="marT15 marB10">
              若您在新增销售订单、销售出库单、销售退货单时销售单价为0,您希望
            </div>

            <el-radio
              class="marT10"
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41
              "
              label="1"
              >不控制</el-radio
            >
            <el-radio
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41
              "
              label="2"
              >提示，允许继续操作</el-radio
            >
            <el-radio
              v-model="
                form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41
              "
              label="3"
              >提示，不允许继续操作</el-radio
            >
            <el-form-item>
              <div class="marT20">
                <span>若您允许销售出库单的出库数量大于订单数量，请勾选</span>
                <div class="x-f">
                  <el-checkbox
                    false-label=""
                    true-label="1"
                    v-model="
                      form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_42
                    "
                  >
                    允许出库数量大于订单数量
                  </el-checkbox>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="可用库存参数" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <div>若您需要调整可用库存请设置预计入库参数和预计出库参数</div>
        </div>
        <div class="big x-f">
          <!-- 左边 -->
          <div style="border-top: white">
            <div class="center x-f">
              <div class="middleL x-c">可用库存 = 当前库存量 +</div>
              <div class="middleR x-c">预计入库量</div>
            </div>
            <div class="bottom x-bc">
              <div class="padd15">
                <el-checkbox-group
                  v-model="
                    form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_51
                  "
                >
                  <el-checkbox label="1" class="padd15"
                    >已审核采购订单数</el-checkbox
                  >

                  <el-checkbox label="2" class="padd15"
                    >未审核产品入库单</el-checkbox
                  >

                  <el-checkbox label="3" class="padd15"
                    >未审核采购入库单</el-checkbox
                  >

                  <el-checkbox label="4" class="padd15"
                    >已审核生产计划单</el-checkbox
                  >

                  <el-checkbox label="5" class="padd15"
                    >已审核调拨出库单(针对收货方)</el-checkbox
                  >
                </el-checkbox-group>

                <!-- <el-checkbox v-model="checked">已审核采购订单数</el-checkbox> -->
              </div>
              <!-- <div class="padd15">
                <el-checkbox v-model="checked">未审核产品入库单</el-checkbox>
              </div>
              <div class="padd15">
                <el-checkbox v-model="checked">未审核采购入库单</el-checkbox>
              </div>
              <div class="padd15">
                <el-checkbox v-model="checked">已审核生产计划单</el-checkbox>
              </div>
              <div class="padd15">
                <el-checkbox v-model="checked"
                  >已审核调拨出库单(针对收货方)</el-checkbox
                >
              </div> -->
            </div>
          </div>
          <span><i class="el-icon-minus"></i></span>
          <!-- 右边 -->
          <div style="border-top: white">
            <div class="x-f">
              <div class="centerL x-c">预计出库量</div>
              <div class="centerR x-c"></div>
            </div>
            <div class="bottom x-bc padd15">
              <el-checkbox-group
                v-model="
                  form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_52
                "
                class="padd15"
              >
                <el-checkbox label="1" class="padd15"
                  >已审核销售订单</el-checkbox
                >
                <el-checkbox label="2" class="padd15"
                  >未审核调拨出库单</el-checkbox
                >
                <el-checkbox label="3" class="padd15"
                  >未审核销售出库单</el-checkbox
                >
                <el-checkbox label="4" class="padd15"
                  >未审核生产领料单</el-checkbox
                >
                <el-checkbox label="5" class="padd15"
                  >零售占用量(线上订单未取货)</el-checkbox
                >
              </el-checkbox-group>

              <!-- <div class="padd15">
                <el-checkbox v-model="checked">已审核销售订单</el-checkbox>
              </div>
              <div class="padd15">
                <el-checkbox v-model="checked">未审核调拨入库单</el-checkbox>
              </div>
              <div class="padd15">
                <el-checkbox v-model="checked">未审核销售入库单</el-checkbox>
              </div>
              <div class="padd15">
                <el-checkbox v-model="checked">未审核生产领料单</el-checkbox>
              </div>
              <div class="padd15">
                <el-checkbox v-model="checked"
                  >零售占用量(线上订单未取货)</el-checkbox
                >
              </div> -->
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; // 卡片标题
import bottomPage from "@/views/components/bottomPage"; // 底部分页
import {
  stockListAPI,
  stockAddUpdateAPI,
} from "@/api/system/maintain/param/inventoryParam";
export default {
  name: "inventoryParam",
  components: {
    cardTitleCom,
    bottomPage,
  },
  data() {
    return {
      form: {
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_INVENTORY_31: "N",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_11: "",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_OVERALL_12: "",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_21: "1", // 需要默认值
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_22: "1",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_23: "", //多选数组
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_24: "",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_25: "",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_26: "",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_PROCURE_27: "",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_41: "1",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_SALE_42: "1",
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_51: [],
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_52: [],
        system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_AMOUNT_CALCULATE_RULE_61:undefined
      },
      checked: [],
      radio: "",
      companyName: "", // 公司名称
      value: true,
      content2: "提示信息",
      input: "",
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    async getDetails() {
      try {
        const { data } = await stockListAPI();

        Object.keys(data).map((key) => {
          if (!data[key]) {
            data[key] = this.form[key];
          }
        });
        this.form = data;
        console.log("this.form", this.form);
      } catch (error) {}
    },
    // configSave() {
    //   //  console.log("this.form", this.form);
    // },
    async configSave() {
      try {
        const valu51 =
          this.form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_51?.filter?.(
            (x) => x
          );
        if (Array.isArray(valu51) && !valu51.length) {
          this.form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_51 =
            null;
        }
        const valu52 =
          this.form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_52?.filter?.(
            (x) => x
          );
        if (Array.isArray(valu52) && !valu52.length) {
          this.form.system_BUSINESS_PROCUREMENT_SALES_STOCK_CONFIG_STOCK_52 =
            null;
        }
        const { data } = await stockAddUpdateAPI(this.form);
        // 发送操作成功消息
        this.$message.success("操作成功");
      } catch (error) {
        // 处理错误
      }
      this.getDetails();
    },
  },
};
</script>

<style lang="scss" scoped>
.big > div {
  text-align: center;
  flex-grow: 1;
  height: 220px;
  margin: 20px;
  &:first-child,
  &:last-child {
    border: 1px solid #949494;
  }
}

.middleL {
  width: 50%;
  height: 40px;
  margin-left: -1px;
  background-color: #ffffff;
  border-bottom: 1px solid #949494;
}

.middleR {
  width: calc(50% + 1px);
  border-left: 1px solid #949494;
  margin-right: -2px;
  height: 40px;
  border-top: 1px solid #949494;
}
.centerL {
  width: 50%;
  border-right: 1px solid #949494;
  border-top: 1px solid #949494;
  height: 40px;
}
.centerR {
  width: calc(50% + 1px);
  border-bottom: 1px solid #949494;
  height: 40px;
  background-color: #ffffff;
  margin-right: -1px;
}

.bottom {
  width: 100%;
  flex-wrap: wrap;
  padding: 10px;
}
.big > span {
  margin-top: -160px; /* 调整负边距的值以向上移动样式的高度 */
}

/* 解决el-input设置类型为number时，中文输入法光标上移问题 */
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/* 解决el-input设置类型为number时，去掉输入框后面上下箭头 */
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
::v-deep .underline-input .el-input__inner {
  border: none !important ; /* 移除默认边框 */
  border-bottom: 1px solid #ccc !important; /* 添加下划线边框 */
  border-radius: 0; /* 可选，如果不想有圆角 */
  background-color: transparent; /* 可选，如果不想有背景色 */
}
.compact-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}


.container {
  padding: 8px 10px;
  background: #e9e9e9;
  font-size: 14px;
}
 .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
</style>
