<!-- 注  原先的页面在最下方，现用新设计的 -->
<template>
  <div class="container">
    <div class="btnTOP">
      <el-button type="primary" size="mini" @click="configSave">保存</el-button>
    </div>
    <cardTitleCom cardTitle="数据参数" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <el-form :model="form" :rules="rules" ref="form">
            <el-form-item
              label="数量小数位保留"
              prop="system_BUSINESS_DATA_CONFIG_11"
              label-width="130px"
            >
              <el-input
                style="width: 80px"
                maxlength="1"
                size="mini"
                class="input"
                v-model="form.system_BUSINESS_DATA_CONFIG_11"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="单价小数位保留"
              prop="system_BUSINESS_DATA_CONFIG_12"
              label-width="130px"
            >
              <el-input
                style="width: 80px"
                size="mini"
                maxlength="1"
                class="input"
                v-model="form.system_BUSINESS_DATA_CONFIG_12"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="金额小数位保留"
              prop="system_BUSINESS_DATA_CONFIG_13"
              label-width="130px"
            >
              <el-input
                style="width: 80px"
                size="mini"
                maxlength="1"
                class="input"
                v-model="form.system_BUSINESS_DATA_CONFIG_13"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="基础资料参数" class="bg-Color">
      <template #cardContent>
        <div class="padd15">
          <el-form>
            <el-form-item
              label="若您希望在新增商品时自动填充税率，请设置默认值:"
              class="compact-form-item"
            ></el-form-item>
            <el-form-item label="默认增值税税率为:" class="compact-form-item">
              <el-input
                style="width: 80px"
                size="mini"
                type="number"
                class="input"
                v-model="form.system_BUSINESS_DATA_CONFIG_BASIC_21"
              ></el-input
              ><span class="marL10">%</span>
            </el-form-item>

            <el-form-item
              label="若您希望新增商品时，自动标记为新品，请设置默认值:"
              class="marT15 compact-form-item"
            ></el-form-item>

            <el-form-item label="商品上架" class="compact-form-item">
              <el-input
                style="width: 80px"
                size="mini"
                :min="1"
                type="number"
                class="input"
                v-model="form.system_BUSINESS_DATA_CONFIG_BASIC_22"
              ></el-input>
              <span class="marL10">天内为新品</span></el-form-item
            >
            <el-form-item label="首页营业数据金额隐藏">
              <el-switch
                v-model="form.system_BUSINESS_DATA_CONFIG_BASIC_23"
                :active-value="true"
                :inactive-value="false"
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; // 卡片标题
import bottomPage from "@/views/components/bottomPage"; // 底部分页
import { dataListAPI, dataAddUpdateAPI } from "@/api/system/maintain/param/dataParam";
export default {
  name: "dataParam",
  components: {
    cardTitleCom,
    bottomPage,
  },
  data() {
    return {
      checked: [],
      radio: "",
      companyName: "", // 公司名称
      value: true,
      content2: "提示信息",
      form: {
        system_BUSINESS_DATA_CONFIG_11: undefined,
        system_BUSINESS_DATA_CONFIG_12: undefined,
        system_BUSINESS_DATA_CONFIG_13: undefined,
        system_BUSINESS_DATA_CONFIG_BASIC_21: "",
        system_BUSINESS_DATA_CONFIG_BASIC_22: "",
      },
      rules: {
        system_BUSINESS_DATA_CONFIG_11: [
          {
            required: true,
            message: "请输入数量小数位保留几位",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[0-8]$/,
            message: "请输入0到8之间的数字",
            trigger: ["blur", "change"],
          },
        ],
        system_BUSINESS_DATA_CONFIG_12: [
          {
            required: true,
            message: "请输入单价小数位保留几位",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[0-8]$/,
            message: "请输入0到8之间的数字",
            trigger: ["blur", "change"],
          },
        ],
        system_BUSINESS_DATA_CONFIG_13: [
          {
            required: true,
            message: "请输入金额小数位保留几位",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[0-8]$/,
            message: "请输入0到8之间的数字",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      dataListAPI().then((res) => {
        console.log("数据：", res);
        // let { receivingTime } = res.data;
        // this.receivingTime = receivingTime;
        this.form = res.data;
        this.$store.dispatch("saveParam", res);
      });
    },
    configSave() {
      console.log("this.formthis.form", this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          dataAddUpdateAPI(this.form).then((res) => {
            this.$message.success(res.msg);
            this.getDetails();
          });
        }
      });
    },
    // configSave() {
    //   this.$refs.companyNameInput.validate((valid) => {
    //     if (valid) {
    //       console.log("ID：", this.form.mallConfigBody.mallConfigurationId);
    //       if (this.form.mallConfigBody.mallConfigurationId == null) {
    //         mallConfigSave(this.form).then((res) => {
    //           console.log("保存成功", res.data);
    //           this.form.mallConfigBody = res.data.mallConfigBody;
    //           this.form.stockConfigItems = res.data.stockConfigItems;
    //           this.form.wxTemplateConfigItems = res.data.wxTemplateConfigItems;
    //           this.$message.success("保存成功");
    //         });
    //       } else {
    //         mallConfigUpdate(this.form).then((res) => {
    //           this.form.mallConfigBody = res.data.mallConfigBody;
    //           this.form.stockConfigItems = res.data.stockConfigItems;
    //           this.form.wxTemplateConfigItems = res.data.wxTemplateConfigItems;
    //           this.$message.success("修改成功");
    //         });
    //       }
    //     } else {
    //       // 表单验证失败，显示错误提示
    //       this.$message.error("请填写公司名称");
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
/* 解决el-input设置类型为number时，中文输入法光标上移问题 */
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/* 解决el-input设置类型为number时，去掉输入框后面上下箭头 */
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
::v-deep .underline-input .el-input__inner {
  border: none !important ; /* 移除默认边框 */
  border-bottom: 1px solid #ccc !important; /* 添加下划线边框 */
  border-radius: 0; /* 可选，如果不想有圆角 */
  background-color: transparent; /* 可选，如果不想有背景色 */
}
.compact-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.container {
  padding: 8px 10px;
  background: #e9e9e9;
  font-size: 14px;
}
.btnTOP {
  text-align: right;
  padding: 0 10px 6px 0;
}
</style>
